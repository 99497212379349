import React from "react";
import {
  PageLayout,
  Background,
  BlogPostNavigation,
} from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class BlogPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { allFishermanBusinessBlogPost } = data;

    return (
      <WebsiteLayout>
        <PageLayout className={"blog-page"} hero={false}>
          <Grid stackable={true} className={"blog-page-container"}>
            <Grid.Column
              width={16}
              textAlign={"center"}
              className={"header-column"}
            >
              <Header as={"h1"} />
            </Grid.Column>
            <Grid.Column width={16} className={"blog-navigation-column"}>
              <Background columns={1}>
                <Background.Pattern type={"CIRCLE"}>
                  <BlogPostNavigation
                    title={""}
                    description={""}
                    featuredPostId={null}
                    alwaysShowSearch={false}
                    columns={3}
                    pageSize={12}
                    tight={true}
                    posts={allFishermanBusinessBlogPost.nodes}
                  />
                </Background.Pattern>
              </Background>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    allFishermanBusinessBlogPost {
      nodes {
        _id
        title
        slug
        author {
          firstName
          lastName
          avatar
          email
        }
        summary
        content
        heroImage
        gatsbyHeroImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        publishedAt
      }
    }
  }
`;
